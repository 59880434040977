.sectionDivider {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    margin: 25px 60px 25px 60px !important;
    padding: 10px !important;
}

.tableDivider {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    grid-gap: 25px !important;
    margin: 25px 60px 25px 60px !important;
}

.overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 46px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 19999999; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

@media (max-width: 991px) {
    .sectionDivider {
        display: flex !important;
        margin: 25px !important;
        padding: 10px !important;
    }

    .tableDivider {
        display: flex !important;
        flex-direction: column !important;
        margin: 25px 0px !important;
        padding: 0px 25px !important;
    }
}

@media (max-width: 767px) {
    .sectionDivider {
        display: flex !important;
        margin: 10px !important;
        padding: 10px !important;
    }

    .tableDivider {
        display: flex !important;
        flex-direction: column !important;
        margin: 10px 0px !important;
        padding: 0px 10px !important;
    }
}
