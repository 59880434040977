.vendorCardsContainer {
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    grid-gap: 25px;
}

.supplierCardSubtitle {
    white-space: nowrap;
    padding-top: 8px !important;
    float: left;
}

.cardBottom {
    border-top: 1px solid #d3d3d3;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
    padding-top: 4px;
}

.cardBottom > p {
    font-size: 12px;
}

.cardBottom > p:first-of-type {
    margin-bottom: 0px;
}

@media (max-width: 991px) {
    .vendorCardsContainer {
        display: grid;
        grid-template-columns: repeat(3, 4fr);
        grid-gap: 25px;
    }
}

@media (max-width: 767px) {
    .vendorCardsContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
    }
}
