.modal{
    z-index: 1000; /* Sit on top */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
button:focus{
    outline: none;
}
.modal-dialog{
    height: 500px;
}



.innerTable{
    background: #fff;
    
    border-radius: 6px;
    border: 1px solid #d1d1d1;
    text-align: center;
    width:100%;
    margin-bottom: 10px;
}

.innerTable tbody td {
    border-top: 1px solid #ddd;
    padding: 8px 10px;
}

.innerTableHeader {
    border-bottom: 2px solid #607D8B;
}

.purchasesHeader{
    margin-top: 1rem;
}

.rowForTable{
    height: 300px;
    overflow-y: scroll;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: rgb(43, 142, 187) !important;
}

.innerTable tbody td:first-child {
   
    border-right: 1px solid #d1d1d1;
}

.paddingForHeader{
    padding-left: 3rem;
}

.modal.fade.show{
    z-index: 100000;
}