.overviewTableContainer {
    margin: 25px 60px;
}

@media(max-width: 991px) {
    .overviewTableContainer {
        margin: 25px;
    }
}

@media(max-width: 767px) {
    .overviewTableContainer {
        margin: 10px;
    }
}