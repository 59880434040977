.navigationContainer {
    position: relative;
}

.navbar {
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 6px -6px #222;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    z-index: 99 !important;
}

.navbar-brand {
    outline: 0 !important;
}

.navbar-brand > img {
    outline: 0 !important;
    height: 34px;
}

.navbar-brand > a > img {
    outline: 0 !important;
    height: 34px;
}

.navigationContainer .bg-light {
    background-color: #ffffff !important;
}

.nav-link {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    width: max-content;
}

.nav-link {
    color: #808080 !important;
    text-decoration: none !important;
}

.nav-link:hover {
    color: #242424 !important;
    text-decoration: none !important;
}

.selectedNavTab {
    border-top: 1px solid #483D8B;
    border-bottom: 1px solid #483D8B;
}

.nav-right {
    display: grid;
    place-items: center;
}

.demo-dashboard-btn {
    white-space: nowrap !important;
    outline: 0 !important;
    border: 1px solid #0074d9 !important;
    background: #ffffff !important;
    color: #0074d9 !important;
    font-size: 12px !important;
    margin-right: 10px !important;
}

.demo-dashboard-btn:hover {
    background: #0074d9 !important;
    color: #ffffff !important;
    cursor: pointer;
}

.contact-us {
    margin-right: 10px;
    cursor: pointer;
}

.logout {
    cursor: pointer;
}

.navbar {
    padding: .1rem 1rem !important;
}

.navbar-toggler {
    outline: 0 !important;
    background: #007bff !important;
}

.memberContent {
    margin-top: 75px !important;
}

@media (max-width: 768px) {
    .nav-right {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding-bottom: 6px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .navbar-brand > img {
        height: 32px;
    }
    
    .nav-item {
        font-size: 10px;
    }

    .nav-item:hover {
        font-size: 10px;
    }
    
    .nav-right {
        flex-direction: row !important;
    }    

    .demo-dashboard-btn {
        white-space: nowrap !important;
        font-size: 8px !important;
        max-width: 150px;
    }
} 