.purchaseOrdersFiltersContainer {
    display: flex;
    flex-direction: column;
    margin: 25px 60px;
}

.purchaseOrdersFilters {
    display: flex;
    justify-content: space-between;
}

.purchaseOrdersFiltersRowOne,
.purchaseOrdersFiltersRowTwo {
    display: flex;
}

.purchaseOrdersFiltersRowTwo {
    height: 32px;
}

.purchaseOrdersFiltersRowOne > .form-group {
    padding: 0;
    margin-right: 10px;
}

.purchaseOrdersFiltersRowOne > .form-group:last-child {
    margin-right: 0;
}

.purchaseOrdersFiltersRowTwo > .form-group {
    padding: 0;
    margin-right: 10px;
}

.purchaseOrdersFiltersRowOne > .form-group > .form-control,
.purchaseOrdersFiltersRowTwo > .form-group > .form-control {
    font-size: 12px;
}

.purchaseOrdersButtonGroup {
    margin-right: 10px;
}

.purchaseOrdersSearch {
    margin-left: 10px;
}

.purchaseOrdersButtonGroup > button,
.purchaseOrdersFiltersRowTwo > button,
.purchaseOrdersFiltersButtons > button {
    font-size: 12px;
}

@media (max-width: 991px) {
    .purchaseOrdersFiltersContainer {
        margin: 25px;
    }

    .purchaseOrdersFilters {
        flex-direction: column;
    }

    .purchaseOrdersSearch {
        margin-left: 0px;
    }

    .purchaseOrdersFiltersRowOne,
    .purchaseOrdersFiltersRowTwo {
        display: flex;
    }

    .purchaseOrdersFiltersButtons > button {
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    .purchaseOrdersFiltersContainer {
        margin: 10px;
    }

    .purchaseOrdersFilters,
    .purchaseOrdersFiltersRowOne {
        display: flex;
        flex-direction: column;
    }

    .purchaseOrdersFiltersRowTwo {
        display: flex;
        flex-direction: row;
    }

    .purchaseOrdersButtonGroup {
        margin: 0 0 15px 0 !important;
    }
    
    .purchaseOrdersFiltersButtons {
        display: flex;
    }

    .purchaseOrdersFiltersButtons > button {
        width: 100%;
        font-size: 12px;
    }
}
