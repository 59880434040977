.terms-dialog{
    display: flex;
    justify-content: center;
    height: 100vh;
}.terms-content {
    /* width: 20vw; */
    /* align-self: center; */
}.terms-body{
    overflow-y: scroll;
    height: 90vh;
    font-weight: normal;
    font-size: small;
}