@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

// BOOTSTRAP OVERRIDES

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-size-base: 0.933rem !default; // Assumes the browser default, typically `16px`
$body-bg: #f8f9fa !default;

@import "bootstrap";
@import "~noty/src/noty.scss";
@import "~noty/src/themes/metroui.scss";
@import "../../node_modules/c3/src/scss/main.scss";
@import"../../node_modules/react-toggle/style.css";

// CUSTOM STYLES

html {
    font-size: 15px;
}
.errorText{
    color:#ff0000bf;
}
#root {
    margin-top: 5rem;
}

// CUSTOM STYLES
