.invoiceFiltersContainer {
    display: flex;
    justify-content: space-between;
    margin: 25px 60px;
}

.invoiceFilters,
.invoiceFiltersRowOne,
.invoiceFiltersRowTwo {
    display: inline-flex;
}

.invoiceFiltersRowTwo {
    height: 32px;
}

.invoiceFiltersRowOne > .form-group {
    padding: 0;
    margin-right: 10px;
}

.invoiceFiltersRowOne > .form-group > .form-control,
.invoiceFiltersRowTwo > .form-group > .form-control {
    font-size: 12px;
}

.invoiceFiltersRowOne > .form-group:last-child {
    margin-right: 0px;
}

.invoiceFiltersRowTwo > .form-group {
    padding: 0;
    margin-right: 10px;
}

.invoiceFiltersButtons > button {
    font-size: 12px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .invoiceFiltersContainer {
        flex-direction: column;
        margin: 25px;
    }

    .filterTitle {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
    }

    .invoiceFilters {
        flex-direction: column;
    }

    .invoiceSearch {
        margin-left: 0px !important;
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .invoiceFiltersContainer {
        flex-direction: column;
        margin: 10px;
    }

    .filterTitle {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
    }

    .invoiceFilters,
    .invoiceFiltersRowOne{
        display: flex;
        flex-direction: column;
    }

    .invoiceFiltersRowTwo {
        display: flex;
        flex-direction: row;
    }

    .invoiceSearch {
        margin: 0 10px 0 0 !important;
    }

    .invoiceFiltersButtons {
        display: flex;
    }

    .invoiceFiltersButtons > button {
        width: 100%;
    }
}
