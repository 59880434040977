.ReactTable .rt-thead {
    overflow-y: scroll;
}

.ReactTable .rt-thead::-webkit-scrollbar {
    display: none;
}
  
.ReactTable .rt-thead::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* width */
.rt-tbody::-webkit-scrollbar {
    width: 4px;
}
  
/* Track */
.rt-tbody::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
.rt-tbody::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
.rt-tbody::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.rt-tbody::-webkit-scrollbar:horizontal {
    height: 4px;
}

/* Track */
.rt-tbody::-webkit-scrollbar-track:horizontal {
    background: #f1f1f1; 
}

/* Handle */
.rt-tbody::-webkit-scrollbar-thumb:horizontal {
    background: #888; 
}

/* Handle on hover */
.rt-tbody::-webkit-scrollbar-thumb:hover:horizontal {
    background: #555; 
}
