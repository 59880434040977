.ReactTable .rt-thead {
    overflow-y: scroll;
}

.growthRebateContainer {
    margin: 0 60px;
}

/* width */
.rt-tbody::-webkit-scrollbar {
    width: 4px;
}
  
/* Track */
.rt-tbody::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
.rt-tbody::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
.rt-tbody::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.rt-tbody::-webkit-scrollbar:horizontal {
    height: 4px;
}

/* Track */
.rt-tbody::-webkit-scrollbar-track:horizontal {
    background: #f1f1f1; 
}

/* Handle */
.rt-tbody::-webkit-scrollbar-thumb:horizontal {
    background: #888; 
}

/* Handle on hover */
.rt-tbody::-webkit-scrollbar-thumb:hover:horizontal {
    background: #555; 
}

@media(max-width: 991px) {
    .growthRebateContainer {
        margin: 0 25px;
    }
}

@media(max-width: 767px) {
    .growthRebateContainer {
        margin: 0 10px;
    }
}
