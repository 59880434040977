.purchaseOrdersTableContainer {
    margin: 25px 60px;
}

.pagination {
    justify-content: flex-end !important;
}

.table {
    overflow: auto;
    display: block;
    table-layout: auto;
    height: 320px;
}

/* width */
.table::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.table::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.table::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.table::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.table::-webkit-scrollbar:horizontal {
    height: 4px;
}

/* Track */
.table::-webkit-scrollbar-track:horizontal {
    background: #f1f1f1; 
}

/* Handle */
.table::-webkit-scrollbar-thumb:horizontal {
    background: #888; 
}

/* Handle on hover */
.table::-webkit-scrollbar-thumb:hover:horizontal {
    background: #555; 
}

.purchaseOrdersTableSearchContainer {
    display: flex;
}

.purchaseOrdersTableSearchBarInput #search-bar-0 {
    min-width: 82vw !important;
}

.purchaseOrdersTableSearchBarClear > button {
    border: 1px solid black;
    margin-left: 10px;
}

.react-bootstrap-table th.sortable {
    overflow: hidden;
}

.react-bootstrap-table-pagination {
    display: flex !important;
}

@media (max-width: 1024px) {
    .purchaseOrdersTableSearchBarInput #search-bar-0 {
        min-width: 79vw !important;
    }
}

@media (max-width: 991px) {
    .purchaseOrdersTableContainer {
        margin: 25px;
    }

    .purchaseOrdersTableSearchBarInput #search-bar-0 {
        min-width: 81vw !important;
    }
}

@media (max-width: 767px) {
    .purchaseOrdersTableContainer {
        margin: 10px;
    }

    .purchaseOrdersTableSearchBarInput #search-bar-0 {
        min-width: 70vw !important;
    }

    .pagination {
        justify-content: flex-end !important;
    }
}
