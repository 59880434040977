.sectionDivider {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    margin: 25px 60px 25px 60px !important;
    padding: 10px !important;
}

.tableDivider {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    grid-gap: 25px !important;
    margin: 25px 60px 25px 60px !important;
}

.jvectormap-container {
    display: flex !important;
    justify-content: center !important;
}

@media (max-width: 991px) {
    .sectionDivider {
        display: flex !important;
        margin: 25px !important;
        padding: 10px !important;
    }

    .tableDivider {
        display: flex !important;
        flex-direction: column !important;
        margin: 25px 0px !important;
        padding: 0px 25px !important;
    }
}

@media (max-width: 767px) {
    .sectionDivider {
        display: flex !important;
        margin: 10px !important;
        padding: 10px !important;
    }

    .tableDivider {
        display: flex !important;
        flex-direction: column !important;
        margin: 10px 0px !important;
        padding: 0px 10px !important;
    }
}
