.purchaseWithinImarkVerifiedContainer {
    margin: 25px 60px;
}

.pagination {
    justify-content: flex-end !important;
}

.purchaseWithinImarkVerifiedInfoModal {
    overflow: hidden;
    table-layout: hidden;
    height: auto;
}

.purchaseWithinImarkVerifiedSearchContainer {
    display: flex;
}

.purchaseWithinImarkVerifiedSearchBarInput #search-bar-0 {
    min-width: 82vw !important;
}

.purchaseWithinImarkVerifiedSearchBarClear > button {
    border: 1px solid black;
    margin-left: 10px;
}

.react-bootstrap-table th.sortable {
    overflow: hidden;
}

.react-bootstrap-table-pagination {
    display: flex !important;
}

#tip {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    text-align: left !important;
    cursor: pointer !important;
}

@media (max-width: 1024px) {
    .purchaseWithinImarkVerifiedSearchBarInput #search-bar-0 {
        min-width: 79vw !important;
    }
}

@media (max-width: 991px) {
    .purchaseWithinImarkVerifiedContainer {
        margin: 25px;
    }

    .purchaseWithinImarkVerifiedSearchBarInput #search-bar-0 {
        min-width: 81vw !important;
    }
}

@media (max-width: 767px) {
    .purchaseWithinImarkVerifiedContainer {
        margin: 10px;
    }

    .purchaseWithinImarkVerifiedSearchBarInput #search-bar-0 {
        min-width: 70vw !important;
    }

    .pagination {
        justify-content: flex-end !important;
    }
}
