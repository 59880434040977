.purchaseWithinImarkContainer{
    margin: 15px 60px;
}
.tier {
    margin: auto;
    width: 30px;
    text-align: center;
    color: #4b9fc5;
    border: 1px solid #4b9fc5;
}
.attainmentRed{
    color: #fff;
    border-radius: 8px;
    text-align: center;
    padding: 0;
    margin: auto;
    width: 50%;
}