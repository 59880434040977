.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 100vh;
    width: -webkit-fill-available;
    background-image: url('../images/imarkHome.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
}

.loginFormContainer {
    width: 320px !important;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d3d3d3;
}

.formContainer {
    padding-top: 10px;
}
