.cardsContainer {
    display: grid;
    grid-template-columns: repeat(4, 3fr);
    grid-gap: 25px;
    margin: 25px 60px;
}

.rebateCard {
    cursor: pointer;
    border: none !important;
    // background: transparent !important;
    border-radius: 0px !important;
    box-shadow: 6px 6px 14px 0 rgba(0, 0, 0, 0.2), -8px -8px 18px 0 rgba(255, 255, 255, 0.55) !important;
    /* box-shadow: inset 6px 6px 14px 0 rgba(0, 0, 0, 0.2), inset -8px -8px 18px 0 rgba(255, 255, 255, 0.55) !important; */
    /* background: linear-gradient(135deg, rgba(0,0,0,0.22), rgba(255,255,255,0.25)); */
    /* box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 12px 0 rgba(255, 255, 255, 0.3); */
}

.cardTitle {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    color: #1D5578;
}

.cardUpdatedOn {
    font-size: 12px;
    font-weight: 600;
    color: #242424;
}

.cardSubtitle {
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    padding-top: 8px !important;
}

.cardBottom {
    border-top: 1px solid #d3d3d3;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
    padding-top: 4px;
}

.cardBottom > p {
    font-size: 12px;
}

.cardBottom > p:first-of-type {
    margin-bottom: 0px;
}

.cardRatings {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cardRatings > p:first-of-type {
    font-size:  12px;
    margin-bottom: 0px;
    margin-right: 6px;
}

.cardBottom > button {
    border-radius: 25px;
    font-size: 12px;
    padding: 2px 14px;
    font-weight: 600;
}

@media (min-width: 768px) and (max-width: 991px) {
    .cardsContainer {
        display: grid;
        grid-template-columns: repeat(3, 4fr);
        grid-gap: 25px;
        margin: 25px;
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .cardsContainer {
        display: flex;
        flex-direction: column;
        margin: 10px;
    }
}