.outSidePurchasesByProductGroupContainer {
    margin: 0px 60px 25px 60px;
}

.outSidePurchasesByProductGroupSearchContainer {
    display: flex;
}

.outSidePurchasesByProductGroupSearchBarInput #search-bar-0 {
    min-width: 82vw !important;
}

.outSidePurchasesByProductGroupSearchBarClear > button {
    border: 1px solid black;
    margin-left: 10px;
}

.react-bootstrap-table th.sortable {
    overflow: hidden;
}

.outsidePurchaseModal {
    width: 90vw !important;
    max-width: 90vw !important;
} 

@media (max-width: 1024px) {
    .outSidePurchasesByProductGroupSearchBarInput #search-bar-0 {
        min-width: 79vw !important;
    }
}

@media (max-width: 991px) {
    .outSidePurchasesByProductGroupContainer {
        margin: 0 25px 25px 25px;
    }

    .outSidePurchasesByProductGroupSearchBarInput #search-bar-0 {
        min-width: 81vw !important;
    }
}

@media (max-width: 767px) {
    .outSidePurchasesByProductGroupContainer {
        margin: 10px;
    }

    .outSidePurchasesByProductGroupSearchBarInput #search-bar-0 {
        min-width: 60vw !important;
    }
}