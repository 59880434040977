.c3ChartsContainer {
    display: grid;
    grid-template-columns: repeat(2, 6fr);
    grid-gap: 25px;
    margin: 25px 60px;
}

@media (max-width: 991px) {
    .c3ChartsContainer {
        grid-gap: 10px;
        margin: 25px;
    }
}

@media (max-width: 767px) {
    .c3ChartsContainer {
        display: flex;
        flex-direction: column;
        margin: 25px 10px !important;
    }
}
