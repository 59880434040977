.tableDivider {
    display: grid !important;
    grid-template-columns: 49% 49% !important;
    grid-gap: 25px !important;
    margin: 25px 60px 25px 60px !important;
}

@media (max-width: 1024px) {
    .tableDivider {
        display: grid !important;
        grid-template-columns: 49% 49% !important;
        grid-gap: 25px !important;
        margin: 25px 60px 25px 60px !important;
    }
}

@media (max-width: 991px) {
    .tableDivider {
        display: flex !important;
        flex-direction: column !important;
        margin: 25px 0px !important;
        padding: 0px 25px !important;
    }
}

@media (max-width: 767px) {
    .tableDivider {
        display: flex !important;
        flex-direction: column !important;
        margin: 10px 0px !important;
        padding: 0px 10px !important;
    }
}
