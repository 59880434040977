.exportButtonWrapper{
    /* width: 33%; */
    display: flex;
    justify-content: space-between;
}

.jvectormap-tip{
    display: visible !important;
}
.jvectormap-container {
    height:100%;
    width:100%;
}
.clearAllWrapper{
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
    align-items: center;
}

.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: top;
    overflow: hidden;
}
.svg-content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}